
<template>
    <BackofficeBase :loader_prop="loader_prop">
      <div v-if="courier_company_id == 0">
        <p><b>הוספת חברת הפצה</b></p>
      </div>
      <div v-else>
        <CourierCompanyMenu :courier_company_id_prop="courier_company_id" />
        <p><b>עריכת חברת הפצה</b></p>
      </div>
      <div v-if="! show_ty">
        <div class="rtl-class">
          <form @submit="courier_company_save">
            <div>
              <div>
                שם חברת הפצה:
              </div>
              <div>
                <input v-model="name" type="text" v-bind:class="{input_error : name_input_error}" />
                <div class="input_error_txt" v-if="name_input_error">
                  שגיאה בשם
                </div>
              </div>
            </div>
            <!--<div>
              <div>
                קידומת שם משתמש חברת הפצה
              </div>
              <div>
                <input v-model="user_name_initial" type="text" v-bind:class="{input_error : user_name_initial_input_error}" />
                <div class="input_error_txt" v-if="user_name_initial_input_error">
                  שגיאה בקידומת שם משתמש של חברת הפצה, חייב להיות עד שני תווים באנגלית
                </div>
              </div>
            </div>-->
            <div>
              <div>
                כתובת חברת הפצה:
              </div>
              <div>
                <input v-model="address" type="text" v-bind:class="{input_error : address_input_error}" />
                <div class="input_error_txt" v-if="address_input_error">
                  שגיאה בכתובת
                </div>
              </div>
            </div>
            <div>
              <div>
                כתובת דוא"ל:
              </div>
              <div>
                <input v-model="email" type="text" v-bind:class="{input_error : email_input_error}" />
                <div class="input_error_txt" v-if="email_input_error">
                  שגיאה בכתובת דוא"ל
                </div>
              </div>
            </div>
            <div v-if="courier_company_id == 0">
              <div>
                סיסמא:
              </div>
              <div>
                <input v-model="password" type="password" v-bind:class="{input_error : password_input_error}" />
                <div class="input_error_txt" v-if="password_input_error">
                  שגיאה בסיסמה
                </div>
              </div>
          </div>

            <div>
              <div>שם איש קשר:</div>
              <div>
                <input v-model="contact_name" type="text" v-bind:class="{input_error : contact_name_input_error}" />
                <div class="input_error_txt" v-if="contact_name_input_error">שגיאה שם איש קשר</div>
              </div>
            </div>
            <div>
              <div>טלפון איש קשר:</div>
              <div>
                <input v-model="contact_phone" type="text" v-bind:class="{input_error : contact_phone_input_error}" />
                <div class="input_error_txt" v-if="contact_phone_input_error">שגיאה טלפון איש קשר</div>
              </div>
            </div>
            
            <div>
              <div>שם איש קשר טכני:</div>
                <div>
                  <input v-model="tech_contact_name" type="text" v-bind:class="{input_error : tech_contact_name_input_error}" />
                  <div class="input_error_txt" v-if="tech_contact_name_input_error">שגיאה שם איש קשר טכני</div>
                </div>
            </div>     
            <div>
              <div>כתובת דוא"ל איש קשר טכני:</div>
              <div>
                <input v-model="tech_contact_email" type="text" v-bind:class="{input_error : tech_contact_email_input_error}" />
                <div class="input_error_txt" v-if="tech_contact_email_input_error">שגיאה כתובת דוא"ל איש קשר טכני</div>
              </div>
            </div>  
            <div>
              <div>טלפון איש קשר טכני:</div>
              <div>
                <input v-model="tech_contact_phone" type="text" v-bind:class="{input_error : tech_contact_phone_input_error}" />
                <div class="input_error_txt" v-if="tech_contact_phone_input_error">שגיאה טלפון איש קשר טכני</div>
              </div>
            </div>  
            
            <div>
            <div>שם איש קשר הנה"ח:</div>
              <div>
                <input v-model="accounting_contact_name" type="text" v-bind:class="{input_error : accounting_contact_name_input_error}" />
                <div class="input_error_txt" v-if="accounting_contact_name_input_error">שגיאה שם איש קשר הנה"ח</div>
              </div>
            </div>      
            <div>
              <div>כתובת דוא"ל איש קשר הנה"ח:</div>
              <div>
                <input v-model="accounting_contact_email" type="text" v-bind:class="{input_error : accounting_contact_email_input_error}" />
                <div class="input_error_txt" v-if="accounting_contact_email_input_error">שגיאה כתובת דוא"ל איש קשר הנה"ח</div>
              </div>
            </div>  
            <div>
              <div>טלפון איש קשר הנה"ח:</div>
              <div>
                <input v-model="accounting_contact_phone" type="text" v-bind:class="{input_error : accounting_contact_phone_input_error}" />
                <div class="input_error_txt" v-if="accounting_contact_phone_input_error">שגיאה טלפון איש קשר הנה"ח</div>
              </div>
            </div>  
            
            <div>
              <div>סטטוס חברת הפצה | 1 פעילה | 0 לא פעילה:</div>
              <div>
                <input v-model="status" type="text" v-bind:class="{input_error : status_input_error}" />
                <div class="input_error_txt" v-if="status_input_error">שגיאה סטטוס חברת הפצה</div>
              </div>
            </div>  

            <div class="submit_cont">
              <input type="submit" value="שמירה" />
            </div>
          </form>
        </div>
      </div>
      <div v-if="show_ty">
        בוצע<br />
        <router-link to="/backoffice/courier_companies">חזרה</router-link>
      </div>
    </BackofficeBase>
  </template>
  
  <script>
  import BackofficeBase from "./BackofficeBase";
  import BackendModel from "../../models/BackendModel";
  import CourierCompanyMenu from "./CourierCompanyMenu";
  
  export default {
    name: 'CourierCompanyEdit',
    components: {
      BackofficeBase,
      CourierCompanyMenu
    },
    data() {
      return {
        loader_prop : false,
        view_data : [],
        show_ty : false,
        courier_company_id : this.$route.query.id,
        email : '',
        name : '',
        address : '',
        password : '',

        contact_name : '',
        contact_phone : '',
        tech_contact_name : '',
        tech_contact_email : '',
        tech_contact_phone : '',
        accounting_contact_name : '',
        accounting_contact_email : '',
        accounting_contact_phone : '',
        status : '',
        //user_name_initial : '',

        name_input_error : false,
        address_input_error : false,
        password_input_error : false,
        email_input_error : false,
        contact_name_input_error :false,
        contact_phone_input_error :false,
        tech_contact_name_input_error :false,
        tech_contact_email_input_error :false,
        tech_contact_phone_input_error :false,
        accounting_contact_name_input_error :false,
        accounting_contact_email_input_error :false,
        accounting_contact_phone_input_error :false,
        status_input_error : false,
        //user_name_initial_input_error : false  
      }
  
    },
    async mounted() {
      this.courier_company_id = this.$route.query.id
      if (this.courier_company_id != '0') {
        let backendModel = new BackendModel()
        let data = await backendModel.backendRequest("/Api/service/backoffice/backoffice_get_courier_company_to_edit", {"id": this.courier_company_id})
        this.email = data.data.data.email
        this.address = data.data.data.address
        this.name = data.data.data.name
        
        this.contact_name = data.data.data.contact_name
        this.contact_phone = data.data.data.contact_phone
        this.tech_contact_name = data.data.data.tech_contact_name
        this.tech_contact_email = data.data.data.tech_contact_email
        this.tech_contact_phone = data.data.data.tech_contact_phone
        this.accounting_contact_name = data.data.data.accounting_contact_name
        this.accounting_contact_email = data.data.data.accounting_contact_email
        this.accounting_contact_phone = data.data.data.accounting_contact_phone
        this.status = data.data.data.status
        //this.user_name_initial = data.data.data.user_name_initial

        console.log(data.data)
      } else {
        this.status =1
      }
    },
    methods : {
      async courier_company_save(e) {
        e.preventDefault()
        let errs = 0
        if (this.name == '') {
          this.name_input_error = true
          errs++
        }
        if (this.address == '') {
          this.address_input_error = true
          errs++
        }
        if (this.password == '' && this.courier_company_id == '0') {
          this.password_input_error = true
          errs++
        }
        if (this.email == '') {
          this.email_input_error = true
          errs++
        }
        if (this.contact_name == '') {
          this.contact_name_input_error = true
          errs++
        }
        /*if (this.contact_phone == '') {
          this.contact_phone_input_error = true
          errs++
        }
        if (this.tech_contact_name == '') {
          this.tech_contact_name_input_error = true
          errs++
        }
        if (this.tech_contact_email == '') {
          this.tech_contact_email_input_error = true
          errs++
        }
        if (this.tech_contact_phone == '') {
          this.tech_contact_phone_input_error = true
          errs++
        }
        if (this.accounting_contact_name == '') {
          this.accounting_contact_name_input_error = true
          errs++
        }
        if (this.accounting_contact_email == '') {
          this.accounting_contact_email_input_error = true
          errs++
        }
        if (this.accounting_contact_phone == '') {
          this.accounting_contact_phone_input_error = true
          errs++
        }*/
        if (this.status != '0' && this.status != '1') {
          this.status_input_error = true
          errs++
        }
        /*if (this.user_name_initial.length >2  ) {
          this.user_name_initial_input_error = true
          errs++
        }*/
        
        if (errs == 0) {
          this.loader_prop = true
          let backendModel = new BackendModel()
          let params = {
            id : this.courier_company_id,
            name : this.name,
            address : this.address,
            password : this.password,
            email : this.email,
            contact_name : this.contact_name,
            contact_phone : this.contact_phone,
            tech_contact_name : this.tech_contact_name,
            tech_contact_email : this.tech_contact_email,
            tech_contact_phone : this.tech_contact_phone,
            accounting_contact_name : this.accounting_contact_name,
            accounting_contact_email : this.accounting_contact_email,
            accounting_contact_phone : this.accounting_contact_phone,
            status : this.status
            //user_name_initial : this.user_name_initial
          }
          await backendModel.backendRequest("/Api/service/backoffice/save_courier_company", params)
          this.loader_prop = false
          this.show_ty = true
        }
      }
    }
  }
  </script>
  
  <style scoped>
  @import "../../assets/style.css"
  </style>
  
  